var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('a-card',{staticClass:"card-signup header-solid",attrs:{"bordered":false,"bodyStyle":{paddingTop: 0}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h5',{staticClass:"font-semibold text-center"},[_vm._v("Request Password Reset")])]},proxy:true}])},[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.sendPasswordResetEmail.apply(null, arguments)}}},[_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'email',
						{
							rules: [
								{ required: true, message: 'Please input your email!' },
								{ email: true, message: 'Please input a valid email!' }
							]
						} ]),expression:"[\n\t\t\t\t\t\t'email',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{ required: true, message: 'Please input your email!' },\n\t\t\t\t\t\t\t\t{ email: true, message: 'Please input a valid email!' }\n\t\t\t\t\t\t\t]\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"placeholder":"Email"},on:{"change":_vm.handleEmailChange}})],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" SEND EMAIL REQUEST ")])],1)],1),_c('p',{staticClass:"font-semibold text-muted text-center"},[_vm._v("Already have a password reset code? "),_c('router-link',{staticClass:"font-bold text-dark",attrs:{"to":{name:'resetPassword'}}},[_vm._v("Reset Password")])],1),_c('p',{staticClass:"font-semibold text-muted"},[_c('router-link',{staticClass:"font-bold text-dark",attrs:{"to":{name: 'Login'}}},[_vm._v("Click here to Login")])],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-up-header",staticStyle:{"background-image":"url('images/bg-signup.jpg')"}},[_c('div',{staticClass:"content"},[_c('h1',{staticClass:"mb-5"},[_vm._v("Forgot Password")]),_c('p',{staticClass:"text-lg"},[_vm._v("If you are unable to remember your password, we have your back!")])])])}]

export { render, staticRenderFns }