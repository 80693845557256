<!-- 
	This is the basic sign up page, it uses the default layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div>

		<!-- Sign Up Image And Headings -->
		<div class="sign-up-header" style="background-image: url('images/bg-signup.jpg')">
			<div class="content">
				<h1 class="mb-5">Forgot Password</h1>
				<p class="text-lg">If you are unable to remember your password, we have your back!</p>
			</div>
		</div>
		<!-- / Sign Up Image And Headings -->
		
		<!-- Sign Up Card -->
		<a-card :bordered="false" class="card-signup header-solid" :bodyStyle="{paddingTop: 0}">
			<template #title>
				<h5 class="font-semibold text-center">Request Password Reset</h5>
			</template>


			<!-- Sign Up Form -->
			<a-form
				id="components-form-demo-normal-login"
				:form="form"
				class="login-form"
				@submit.prevent="sendPasswordResetEmail"
			>

				<a-form-item class="mb-10">
					<a-input @change="handleEmailChange"
						v-decorator="[
							'email',
							{
								rules: [
									{ required: true, message: 'Please input your email!' },
									{ email: true, message: 'Please input a valid email!' }
								]
							},
						]"
						placeholder="Email"
					>
					</a-input>
				</a-form-item>
				<a-form-item>
					<a-button type="primary" block html-type="submit" class="login-form-button">
						SEND EMAIL REQUEST
					</a-button>
				</a-form-item>
			</a-form>
			<!-- / Sign Up Form -->

			<p class="font-semibold text-muted text-center">Already have a password reset code? <router-link :to="{name:'resetPassword'}" class="font-bold text-dark">Reset Password</router-link></p>
			<p class="font-semibold text-muted"><router-link :to="{name: 'Login'}" class="font-bold text-dark">Click here to Login</router-link></p>
		</a-card>
		<!-- / Sign Up Card -->

	</div>
</template>

<script>

    import CUSTOM_CONSTANTS from "@/constants";
    import { required, email, minLength } from 'vuelidate/lib/validators';

	export default ({
		data() {
			return {
				// Sign up form object.
				form: this.$form.createForm(this, { name: 'signup_basic' }),
                email: "",
			}
		},
		validations: {
            email: {
                required,
                email,
                minLength: minLength(CUSTOM_CONSTANTS.size.min.email)
            },
		},
		methods: {
            handleEmailChange(e)
            {
                this.email = e.target.value;
            },
			// Handles input validation after submission.
			sendPasswordResetEmail() {
                let _thisObj = this;
				this.form.validateFields((err, values) => {
                    console.log('Received values of form: ', values) ;
					if ( !err ) {
                        _thisObj.$store.dispatch('REQUEST_AUTH_REQUEST_PASSWORD_RESET', {
                            email: values.email,
                        })
                            .then(function(){
								/*_thisObj.email = "";*/
                                _thisObj.$store.dispatch("COMMIT_SET_MESSAGES",["You should receive an email shortly containing a code to help you reset your password."]);
								/*_thisObj.$router.push({name: "resetPassword"});*/
                                _thisObj.form.setFieldsValue({
                                    email: ""
                                })
                                _thisObj.email = ""
                            });
					}
                });
			},
		},
		computed: {
            hasEmail: function()
            {
                return typeof this.email !== "undefined" && this.email !== null && this.email.length > 0;
            },
		}
	})

</script>

<style lang="scss">
</style>